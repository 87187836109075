import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

function RequestEmailCodeForm({
  showEmailValidationForm,
  email,
  user,
  confirmedEmailValue,
  isEmailInvalid,
  verifyEmail,
  isEmailVerified,
}) {
  return (
    <>
      {showEmailValidationForm &&
        (!user?.email_verified || email !== confirmedEmailValue) &&
        !isEmailInvalid &&
        email && (
          <span className="status-unconfirmed status ms-2">
            <span className="text-danger fw-bold">
              <FontAwesomeIcon icon={faCircleExclamation} className="text-danger me-1" />
              Unconfirmed
            </span>
            <Button className="ps-2 pe-2 pt-0 pb-0" color="link" onClick={verifyEmail}>
              Request code
            </Button>
          </span>
        )}
      {!showEmailValidationForm && (user?.email_verified || isEmailVerified) && (
        <span className="status-confirmed status ms-2">
          <span className="text-success fw-bold">
            <FontAwesomeIcon icon={faCircleCheck} className="text-success me-1" />
            Confirmed
          </span>
        </span>
      )}
    </>
  );
}

RequestEmailCodeForm.propTypes = {
  confirmedEmailValue: PropTypes.string,
  email: PropTypes.string,
  isEmailInvalid: PropTypes.bool,
  showEmailValidationForm: PropTypes.bool,
  user: PropTypes.shape({
    email_verified: PropTypes.bool,
  }),
  verifyEmail: PropTypes.func,
  isEmailVerified: PropTypes.bool,
};

export default RequestEmailCodeForm;
