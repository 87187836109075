// This function recursively processes the API error data,
// removing any empty nodes.

// REST API validation error data structure:
// {
//   "code": 400,
//   "message": "Validation Failed",
//   "errors": {
//     "children": {
//       "field_name_1": {}, // This field has no errors
//       "field_name": {
//         "errors": ['An error message'],
//         "children": {
//           ...
//         }
//       }
//     }
//   }
// }
export function getErrorsFromFetcherData(data) {
  // If this object doesn't have children or errors,
  // we don't want to include it, so return null.
  if (!data || (!data.children && !data.errors)) {
    return null;
  }

  const fields = Object.keys(data?.children || []);

  // Recursively loop through children.
  const returnChildren = {};

  fields.forEach((fieldName) => {
    const field = data.children[fieldName];

    const childErrors = getErrorsFromFetcherData(field);

    if (childErrors) {
      returnChildren[fieldName] = childErrors;
    }
  });

  // If this field has no children with errors or children,
  // and has no errors of its own, we'll return null.
  let returnField = null;

  // If we have children, add those to the return object.
  if (Object.keys(returnChildren).length > 0) {
    returnField = { children: returnChildren };
  }

  // If we have errors at the top level, check if we have children.
  // If so, preserve them. If not, add the errors to a new object.
  if (data.errors) {
    returnField = returnField === null
      ? { errors: data.errors }
      : { ...returnField, errors: data.errors };
  }

  return returnField;
}