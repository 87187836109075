import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button } from 'reactstrap';

function RequestMobileCodeForm({
  showPhoneValidationForm,
  user,
  mobile,
  confirmedMobileValue,
  isPhoneInvalid,
  verifyPhone,
  isMobileVerified,
}) {
  return (
    <>
      {showPhoneValidationForm && (!user?.mobile_verified || mobile !== confirmedMobileValue) && !isPhoneInvalid && (
        <span className="status-unconfirmed status ms-2">
          <span className="text-danger">
            <FontAwesomeIcon icon={faCircleExclamation} className="text-danger me-1" />
            Unconfirmed
          </span>
          <Button color="link" className="ps-2 pe-2 pt-0 pb-0" onClick={verifyPhone}>
            Request code
          </Button>
        </span>
      )}
      {!showPhoneValidationForm && (user?.mobile_verified || isMobileVerified) && (
        <span className="status-confirmed status ms-2">
          <span className="text-success">
            <FontAwesomeIcon icon={faCircleCheck} className="text-success me-1" />
            Confirmed
          </span>
        </span>
      )}
    </>
  );
}

RequestMobileCodeForm.propTypes = {
  confirmedMobileValue: PropTypes.string,
  isPhoneInvalid: PropTypes.bool,
  mobile: PropTypes.string,
  showPhoneValidationForm: PropTypes.bool,
  user: PropTypes.shape({
    mobile_verified: PropTypes.bool,
  }),
  verifyPhone: PropTypes.func,
  isMobileVerified: PropTypes.bool,
};

export default RequestMobileCodeForm;
